import { FC, ReactNode, useEffect } from 'react';
import serverVars from 'server-vars';
import classNames from 'classnames';

import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';
import { ModalContainer, ModalContainerProps } from 'dibs-elements/exports/ModalContainer';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';

import styles from './styles/SendFeedbackModal.scss';

type SendFeedbackModalProps = ModalContainerProps & {
    type?: 'default';
    title?: ReactNode;
    iframeClassName?: string;
    feedBackSurveyUrl: string;
    onSubmit?: () => void;
};

const EVENT_TYPE = 'message';

const defaultTitle = (
    <FormattedMessage id="dal.SendFeedbackModal.sendFeedback" defaultMessage="Send Feedback" />
);

export const SendFeedbackModal: FC<SendFeedbackModalProps> = ({
    feedBackSurveyUrl,
    title = defaultTitle,
    modalPosition = 'center',
    iframeClassName = styles.sendFeedbackIframe,
    onClose,
    onSubmit,
    isOpen,
    ...restProps
}) => {
    const sellerId = serverVars.get('sellerData.id');
    const pageType = serverVars.get('tracking.pageType');
    const params = new URLSearchParams({
        sellerId,
        pageType,
    });

    useEffect(() => {
        const onSurveySubmit = (event: MessageEvent): void => {
            const isFeedbackSubmitted =
                event.origin?.includes('getfeedback') && event.data === 'submittedResponse';

            if (isFeedbackSubmitted && onSubmit) {
                onSubmit();
            }
        };

        if (isOpen && onSubmit) {
            window.addEventListener(EVENT_TYPE, onSurveySubmit);
        }

        return () => {
            if (onSubmit) {
                window.removeEventListener(EVENT_TYPE, onSurveySubmit);
            }
        };
    }, [isOpen, onSubmit]);

    return (
        <ModalContainer
            dataTn="send-feedback-modal"
            modalPosition={modalPosition}
            onClose={onClose}
            isOpen={isOpen}
            {...restProps}
        >
            <ModalCloseButton onClick={onClose} />
            <BarHeader title={title} />
            <ModalBody className={classNames(dibsCss.flex, dibsCss.justifyCenter)}>
                <iframe
                    className={iframeClassName}
                    src={`${feedBackSurveyUrl}?gf_footer_background_off&gf_hide_progress&${params.toString()}`}
                    // Can't be added to class as it's Iframe and used for loading skeleton
                    style={{ backgroundColor: '#E3E3E3' }}
                />
            </ModalBody>
        </ModalContainer>
    );
};
