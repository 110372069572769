import { datadogRum } from '@datadog/browser-rum';
import serverVars from 'server-vars';
import { AbTestV2Type } from '../../server/authentication/dalAuthentication';

const DD_RUM = serverVars.get('DD_RUM');
const sellerData = serverVars.get('sellerData');
const abTests = serverVars.get('abTestsV2') as Record<string, AbTestV2Type>;

(() => {
    if (!DD_RUM) {
        return;
    }

    const { env, service, version, sessionSampleRate } = DD_RUM;
    datadogRum.init({
        applicationId: 'fdc90732-140b-4e34-ab94-3808efa2b6fe',
        clientToken: 'pub30a5825c3fa6ca1d0f3c5db650fed76e',
        site: 'datadoghq.com',
        env,
        service,
        version,
        sessionSampleRate,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        actionNameAttribute: 'data-tn',
        defaultPrivacyLevel: 'mask-user-input',
    });

    if (sellerData) {
        datadogRum.setUser({
            id: sellerData?.id,
            name: sellerData?.seller?.sellerProfile?.company,
        });
    }

    datadogRum.startSessionReplayRecording();

    // track ab tests decisions
    for (const [test, config] of Object.entries(abTests ?? {})) {
        if (test && config?.variant) {
            datadogRum.setGlobalContextProperty(test, config.variant);
        }
    }
})();
