import { getRelayEnvironment } from '../clientRelayEnvironment';
import { NavBarContext, ForceReloadOptions } from '../../../exports/NavBarContext';

export type RenderNavbar = (
    context: NavBarContext,
    options: ForceReloadOptions & { refetchIndex: number }
) => void;

/**
 * Creates a factory function that when executed, returns another function that either returns or creates
 * a nav bar context that can be used by client code to interact with the nav bar.
 *
 * Usage:
 *   const ctx = window.navbarContext();
 *   ctx.forceReload();  // reload the queries to display the badge counts
 */
export default function contextFactory(renderNavbar: RenderNavbar): () => NavBarContext {
    let refetchIndex = 1;

    const context: NavBarContext = Object.freeze({
        relayEnvironment: getRelayEnvironment('seller'),

        forceReload(options?: ForceReloadOptions): Promise<void> {
            return new Promise(resolve => {
                const navbarContext = window.navbarContext();
                const { customBannerText } = options || {};

                renderNavbar(navbarContext, {
                    refetchIndex,
                    customBannerText,
                });

                refetchIndex = refetchIndex + 1;
                resolve();
            });
        },
    });

    function getContext(): NavBarContext {
        return context;
    }

    return getContext;
}
