import { trackEvent, eventNameConstants } from 'dibs-tracking';

type TTrackNavClickProps = Partial<{
    action: string | null;
    label: string | null;
    interactionType?: string | null;
    trigger?: string | null;
}>;

type TPrepareTrackEvent = (label: string) => void;

const category = 'header nav';
const event = 'e_ga_event';

const TRACK_ACTIONS = {
    MY_ACCOUNT: 'my account',
    SUPPORT: 'support',
};

const trackNavClick = ({ action, label, interactionType, trigger }: TTrackNavClickProps): void => {
    if (action) {
        action = action.toLowerCase();
        label = (label || '').toLocaleLowerCase();
        interactionType = (interactionType || '').toLocaleLowerCase();
        trigger = (trigger || '').toLocaleLowerCase();
        trackEvent({
            eventName: eventNameConstants.EVENT_NAVIGATION,
            interaction_type: interactionType,
            step_interaction_name: 'click',
            trigger: trigger ? trigger : label,
            event,
            category,
            action: `${action} clicked`,
            label,
        });
    }
};

const prepareTrackEvent =
    (
        action: TTrackNavClickProps['action'],
        interactionType?: TTrackNavClickProps['interactionType'],
        trigger?: TTrackNavClickProps['trigger']
    ): TPrepareTrackEvent =>
    (label: TTrackNavClickProps['label']) =>
        trackNavClick({ action, label, interactionType, trigger });

export { trackNavClick, prepareTrackEvent, TRACK_ACTIONS, type TPrepareTrackEvent };
